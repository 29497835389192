/* eslint-disable */
import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
//import "../fake-db";
import "../styles/_app.scss";
import EgretLayout from "./app-layout/app-layout";
import EgretTheme from "./app-layout/EgretTheme/EgretTheme";
import AppContext from "./appContext.js";
import Auth from "./auth/Auth";
// import AuthGuard from "./auth/AuthGuard";
import Snackbar from "./components/snackbar";
import Mobile from "./containers/Mobile/Mobile";
import { Store } from "./redux/Store";
import routes from "./routes";
import { onMobile } from "./utils/onMobile";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import AuthCheck from "./Layout/AuthCheck";
import GetUserType from "./Layout/GetUserType";
import { ErrorBoundary } from "react-error-boundary";
import Home from "./error-boundaries/home";
import AuthGuard from "./auth/AuthGuard";
import ReactQueryClient from "./ReactQueryClient";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

const App = function () {
  const [isMobile, setOnMobile] = React.useState(false);

  React.useEffect(() => {
    if (onMobile(navigator.userAgent)) {
      setOnMobile(true);
    }
  }, []);

  const browser = (function () {
    const test = function (regexp) {
      return regexp.test(window.navigator.userAgent);
    };
    switch (true) {
      case test(/edg/i):
        return "Microsoft Edge";
      case test(/trident/i):
        return "Microsoft Internet Explorer";
      case test(/firefox|fxios/i):
        return "Mozilla Firefox";
      case test(/opr\//i):
        return "Opera";
      case test(/ucbrowser/i):
        return "UC Browser";
      case test(/samsungbrowser/i):
        return "Samsung Browser";
      case test(/chrome|chromium|crios/i):
        return "Google_Chrome";
      case test(/safari/i):
        return "Apple Safari";
      default:
        return "Other";
    }
  })();

  return isMobile || !["Google_Chrome", "Microsoft Edge"].includes(browser) ? (
    <Mobile />
  ) : (
    <ErrorBoundary FallbackComponent={Home}>
      <QueryClientProvider client={queryClient}>
        {process.env.NODE_ENV === "development" && (
          <ReactQueryDevtools initialIsOpen={false} />
        )}
        <AppContext.Provider value={{ routes }}>
          <Provider store={Store}>
            <ReactQueryClient>
              <EgretTheme>
                <Auth>
                  <AuthGuard>
                    <BrowserRouter>
                      <GetUserType>
                        <EgretLayout />
                      </GetUserType>
                    </BrowserRouter>
                  </AuthGuard>
                </Auth>
                <Snackbar />
              </EgretTheme>
            </ReactQueryClient>
          </Provider>
        </AppContext.Provider>
      </QueryClientProvider>
    </ErrorBoundary>
  );
};

export default App;
