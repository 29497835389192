// const productionApiUrls = {
//   timeLineBaseUrl: "https://scanapi.assertion.cloud/",
//   baseURL: "https://scanapi.assertion.cloud/",
//   schedulesBaseUrl: "https://scanapi.assertion.cloud/",
// };

import { baseURL } from "config";

//export default productionApiUrls;

const demoApiUrls = {
  baseURL: baseURL,
  timeLineBaseUrl: baseURL,
  schedulesBaseUrl: baseURL,
  visibilityDashboardBaseUrl: baseURL,
  whiteListBaseUrl: `${baseURL}defender`,
};

export default demoApiUrls;
// const stagingApiUrls = {
//   baseURL: "https://3hnjzmdq78.execute-api.us-east-1.amazonaws.com/staging/",
//   timeLineBaseUrl:
//     "https://k0mfuxisu4.execute-api.us-east-1.amazonaws.com/staging/",
//   schedulesBaseUrl:
//     "https://h5t0owak21.execute-api.us-east-1.amazonaws.com/staging/",
// };

// export default stagingApiUrls;

// const developmentApiUrls = {
//   baseURL:
//     "https://rcxy1cwy6i.execute-api.us-east-2.amazonaws.com/development/",
//   timeLineBaseUrl:
//     "https://1n5skqdkyc.execute-api.us-east-2.amazonaws.com/development/",
//   schedulesBaseUrl:
//     "https://93e2p2y6ki.execute-api.us-east-2.amazonaws.com/development/",
//   visibilityDashboardBaseUrl:
//     "https://dq8emp6pyj.execute-api.us-east-2.amazonaws.com/development/",
//   whiteListBaseUrl:
//     "https://rcxy1cwy6i.execute-api.us-east-2.amazonaws.com/development/defender",
// };

// export default developmentApiUrls;
