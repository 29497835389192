const apiEndPoints = {
  demoAPI: "https://jsonplaceholder.typicode.com/todos",
  registrationAPI: "users/register",
  userconfirmationAPI: "users/confirmUser",
  loginAPI: "users/login",
  verifyOtp: "users/verifyCode",
  linkValidation: "users/sendInviteeOTP",
  getRefreshToken: "users/getTokenRefreshed",
  downloadUtility: "webappscan/utility",
  securityStrength: "webappreports/securityStrength",
  scanSummary: "webappreports/scanSummary/",
  usagetype: "webappreports/usagetype/",
  deploymentType: "webappreports/deploymentType/",
  serverDistribution: "webappreports/serverDistribution/",
  globalDistribution: "webappreports/globalDistribution/",
  connectedServers: "webappreports/connectedServers/",
  threatCategories: "webappreports/threatCategories/",
  fileUpload: "webappusers/uploadinfo",
  securityIssues: "webappreports/securityIssues/",
  scanStatus: "webappscan/getScan",
  myScans: "webappscan/allScans",
  freeScans: "webappscan/allFreeScans",
  professionalScans: "webappscan/allScansUnderPP",
  oneTimeScans: "webappscan/allFreeScans",
  riskLevel: "webappreports/riskLevel/",
  connectedServerRiskLEvel: "webappreports/connRiskLevel/",
  scandetails: "webappscan/scanDetails/",
  shareReport: "webappscan/shareScan",
  cloudScan: "webappreports/cloudScan/",
  downloadScanReport: "webappreports/paidReport/",
  downLoadPaidReport: "webappreports/getDetailedReport/",
  dateRangeReport: "webappscan/generateDateRangeDetailedReportPDF",
  reportOTPProblem: "webappusers/createSupportRequest",
  inviteeLogin: "webappusers/inviteeLogin",
  inviteeThreatCategories: "webappreports/invitee/threatCategories",
  inviteeSecurityIssues: "webappreports/invitee/securityIssues",
  inviteeScanSummary: "webappreports/invitee/scanSummary",
  inviteeUsagetype: "webappreports/invitee/usagetype",
  inviteeRiskLevel: "webappreports/invitee/riskLevel",
  inviteeDeploymentType: "webappreports/invitee/deploymentType",
  inviteeServerDistribution: "webappreports/invitee/serverDistribution",
  inviteeGlobalDistribution: "webappreports/invitee/globalDistribution",
  inviteeConnectedServers: "webappreports/invitee/connectedServers",
  inviteeConncetedServerRiskLEvel: "webappreports/invitee/connRiskLevel",
  inviteeSacnInfo: "webappscan/invitee/scanDetails",
  inviteeCloudScan: "webappreports/invitee/cloudScan",
  inviteeDownloadScanReport: "webappreports/invitee/paidReport",
  allIntegrations: "webappusers/integrations",
  getKey: "webappscan/getKey/",
  getUploadUrl: "webappusers/getUploadURL",
  setScanInfo: "webappusers/uploadinfo",
  getSbcInfo: "webappusers/scheduleInfo",
  startScan: "webappusers/initiateScan",
  integrationSubscription: "webappusers/subscribeNotification",
  getScanConnectionStatus: "webappscan/getScanStatus/",
  setEMSConnection: "webappscan/setScanConfig",
  getSbcList: "webappscan/getScanConfig",
  selectedSBC: "webappscan/setScanConfig",
  getProductList: "webappusers/getProductList",
  getUserInfo: "webappusers/getUserInfo",
  signOut: "webappusers/logout",
  getAllProducts: "webappusers/getAllProducts",
  setUserProfile: "webappusers/updateUserInfo",
  getUserProfile: "webappusers/getUserInfo",
  getInfraStructure: "webappscan/checkInfrastructureStatus",
  getScanProgress: "webappscan/getScanProgress",
  getScanSummary: "webappreports/scanSummary/",
  getScanStats: "webappusers/riskScoreForScan",
  getScanDetails: "webappscan/scanDetails/",
  getEMSConnection: "webappscan/getScanStatus/",
  getThreatVisibility: "webappreports/attackVisibility/",
  getScannedData: "webappreports/dataScanned/",
  getBreachData: "webappreports/breachIdentification/",
  getPolicyData: "webappreports/summaryStats/",
  getAttackOrigination: "webappreports/attackOrigination/",
  getScanNowTrending: "webappusers/getDashboardData",
  getSummaryReport: "webappreports/generateSummaryReportPDF/",
  getSchedule: "webappscan/getScheduleByUser",
  setSchedule: "webappscan/createSchedule",
  deleteSchedule: "webappscan/deleteSchedule",
  updateSchedule: "webappscan/updateSchedule",
  purchaseScan: "webappusers/purchaseLicenseSupport",
  setTimelineView: "webappusers/fetchTimeLineView",
  setExecutiveSummary: "webappusers/fetchExecutiveSummary",
  downloadLinuxUtility: "webappscan/linuxUtility",
  linuxUtilityGuide: "webappscan/linuxUtilityGuide",
  getScanStateOne: "webappusers/fetchRiskProfile",
  setVerticalTimelineView: "webappusers/fetchTimeLineViewVertical",
  avayaDownloadLinuxUtility: "webappscan/downloadLinuxUtility",
  viewSchedules: "webappusers/viewSchedules",
  resetSchedules: "webappscan/deleteSchedule",
  editSchedules: "webappscan/updateSchedule",
  userLicenses: "webappusers/findLicensesOfUser",
  operationDashboard: "webappscan/fetchOperationsDashboard",
  verifySbcPassword: "webappscan/verifySbcPassword",
  attackDisposition: "webappreports/attacksDisposition",
  visibility: {
    robocalls: "webappreports/robocallsGraph/",
    vishing: "webappreports/userImpactedRobocallGraph/",
    incomingCalls: "webappreports/incomingCalls",
    tollFreeData: "webappreports/tollFreePumping",
    tollFraud: "webappreports/tollFraud",
    top5ScamCallers: "webappreports/top5SpamTargetNumbers",
    top5TargetNumbers: "webappreports/top5TargetNumbers",
    callOriginations: "webappreports/callOriginations",
    outboundBlocked: "webappreports/outboundBlockedCalls",
  },
  whitelist: "webappdefender/whitelist",
  blacklist: "webappdefender/blacklist",
  spam: "webappdefender/update-feedback",
  scam: "webappdefender/update-feedback",
  addSpam: "webappdefender/report-number",
  addScam: "webappdefender/report-number",
  enforcement: {
    inboundTrafficEnforcement: "webappreports/inboundTrafficEnforcement",
    enforcementDashBoardStats: "webappreports/enforcementDashBoardStats",
  },
  securityIncidents: "webappusers/fetchSecurityIncidents",
  telemetrySecurityIncidents: "webappusers/fetchTelemetrySecurityIncidents",
  getDefenderDeviceConfig: "webappusers/getDeviceConfiguration",
  updateDefenderDeviceConfig: "webappusers/updateDeviceConfiguration",
  getScannerConfig: "webappreports/getScannerConfigurationStatus",
  updateScannerConfig: "webappreports/updateScannerConfiguration",
  getDefenderId: "webappusers/getDefenderdetails",
  getCustomerId: "webappusers/getCustomerId",
  registrationScreened: "webappreports/registrations-screened",
  remoteWorkerSecurity: "webappreports/remoteWorkerSecurity",
  getRealTimeScans: "webappreports/getRealTimeScans",
  editDefenderName: "webappreports/editDefenderName",
  getUserType: "webappreports/getUserType",
  getPartnerStats: "webappreports/getPartnerViewStats",
  remoteWorkerTraffic: "webappreports/remoteWorkerSecurity",
  associateUserTocustomer: "webappreports/associateUserToCustomer",
  getUsers: "webappreports/getUsers",
  deleteUserFromCustomer: "webappreports/deleteUserFromCustomer",
  apiKeys: "webappreports/apiKeys",
  requestKey: "webappreports/requestKey",
  editAiSettings: "webappreports/editAiSettings",
  getAiSettings: "webappreports/getAiSettings",
  getvoiceInfraSettings: "webappreports/getvoiceInfraSettings",
  updatevoiceInfraSettings: "webappreports/updatevoiceInfraSettings",
  serviceNumbers: "webappreports/serviceNumbers",
  getServiceNumbers: "webappscan/getServiceNumbers",
  assertionPopulatedList: "webappreports/assertionPopulatedList",
  addAllowDenyOutBound: "webappreports/addAllowDenyOutBound",
  getAllowDenyOutBound: "webappreports/getAllowDenyOutBound",
  deleteAllowDenyOutBound: "webappreports/deleteAllowDenyOutBound",
  editAllowDenyOutBound: "webappreports/editAllowDenyOutBound",
  getOrigRemoteIps: "webappreports/getOrigRemoteIps",
  deleteVoiceInfra: "webappreports/deleteVoiceInfra",
  categoriesGraph: "webappreports/categoriesGraph",
  getEsIndexByAlias: "webappreports/getEsIndexByAlias",
  updateEsIndexByAlias: "webappreports/updateEsIndexByAlias",
  telcoViewGraph: "webappreports/telcoViewGraph",
  serviceNumberGraph: "webappreports/serviceNumberGraph",
  top5ScamExport: "webappreports/top5ScamExport",
  outboundWorldMap: "webappreports/outboundWorldMap",
  getSbcInterfaceMappings: "webappreports/getSbcInterfaceMappings",
  getSbcNameRegion: "webappreports/getSbcNameRegion",
  saveSbcNameRegion: "webappreports/saveSbcNameRegion",
  getSourceIPs: "webappreports/getSourceIPs",
  saveSbcInterfaceMappings: "webappreports/saveSbcInterfaceMappings",
  remoteWorkerCount: "webappreports/remoteWorkerCount",
  remoteWorkerTopAttackers: "webappreports/remoteWorkerTopAttackers",
  remoteWorkerEndpointBruteForce:
    "webappreports/remoteWorkerEndpointBruteForce",
  ipInfo: "webappreports/ipInfo",
  exportCCServiceNumberDetails: "webappreports/exportCCServiceNumberDetails",
  remoteWorkerTrafficGraph: "webappreports/remoteWorkerTraffic",
  vishingGraphViewDetails: "webappreports/vishingGraphViewDetails",
  getScannerIds: "reports/getScannerIds",
  confidenceScoresHistory: "scan/confidenceScoresHistory",
  updateSecurityIncidentEmails: "webappscan/updateSecurityIncidentEmails",
  aiCategories: "scan/getaiCategories",
  activeSecurityIncidents: "webappscan/getactiveSecurityIncidents",
  getPartnerViewAlertStats: "webappscan/getPartnerViewAlertStats",
  inboundCauseCodeGraph: "webappscan/inboundCauseCodeGraph",
  inboundCauseCodeGraphViewDetails:
    "webappscan/inboundCauseCodeGraphViewDetails",
  updatenumberNormalization: "scan/updatenumberNormalization",
  aiInsights: "scan/aiInsights",
  dncCallRestrictionList: "webappscan/dncCallRestrictionList",
  updateTDoSThreshold: "webappscan/updateTDoSThreshold",
  updateEmailNotificationRules: "webappscan/updateEmailNotificationRules",
  updateWorkFlowManagers: "scan/updateWorkFlowManager",
  getTriggeredActivities: "webappscan/getTriggeredActivities",
  invalidToNumberType: "scan/invalidToNumberType",
  updateAiAlogsConfig: "scan/updateAiAlogsConfig",
  lastSyncData: "scan/lastSyncData",
  updateIncidentState: "scan/updateIncidentState",
  fetchHorizontalTimelineViewFromEs: "scan/fetchHorizontalTimelineViewFromEs",
  makeScannerPrimary: "webappscan/makeScannerPrimary",
  getScannersDetails: "webappscan/getScannersDetails",
  getDefenderHADetails: "webappscan/getDefenderHADetails",
  getFeatureLicenseData: "webappscan/getFeatureLicenseData",
  getMonthlySessionsData: "webappscan/getMonthlySessionsData",
  blockZoneDetails: "webappscan/blockZoneDetails",
  approveNumber: "webappdefender/approve-number",
  callHistoryOfNumber: "webappscan/callHistoryOfNumber",
  getUserExtensionRanges: "webappscan/getUserExtensionRanges",
  saveTransferedExensionRanges: "webappscan/saveTransferedExensionRanges",
  updateProcessNames: "webappscan/updateProcessNames",
  getServiceUptimeStatus: "webappscan/inboundCauseCodeGraphViewDetails",
};

export { apiEndPoints };
