import React from "react";
import { QueryClientProvider, useQueryClient } from "react-query";
import useMessage from "./hooks/useMessage";

const ReactQueryClient = ({ children }) => {
  const message = useMessage();

  const queryClient = useQueryClient();

  queryClient.setDefaultOptions({
    mutations: {
      onError: (error) => {
        if (error?.response?.status === 403) {
          message("error", "Unauthorized to perform this action");
        }
      },
    },
    queries: {
      onError: (error) => {
        if (error?.response?.status === 403) {
          message("error", "Unauthorized to perform this action");
        }
      },
      refetchOnWindowFocus: false,
      retry: false,
    },
  });

  return (
    <QueryClientProvider client={queryClient}> {children}</QueryClientProvider>
  );
};

export default ReactQueryClient;
