import { getItem } from "app/services/localStorageService";

export const logout = () => {
  const customCognitoClientId = getItem("cognitoClientId");

  localStorage.clear();

  window.location.href = customCognitoClientId
    ? "https://affiliateservices.gecompany.com/logoff/logoff.jsp"
    : window.location.origin + "/session/signin";
};
