import { showMessage } from "app/redux/actions/snackbar";
import { useDispatch } from "react-redux";

const useMessage = () => {
  const dispatch = useDispatch();

  const show = (type = "error", data) => {
    if (type === "success") {
      return dispatch(
        showMessage({
          show: true,
          type,
          message: data
        })
      );
    }

    dispatch(
      showMessage({
        show: true,
        type,
        message:
          typeof data === "string" ? data : data?.response?.data?.message || "Please try again"
      })
    );
  };

  return show;
};

export default useMessage;
